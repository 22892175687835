import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { useIntl } from 'react-intl';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;
  const { listingFields } = listingConfig || {};
  const isPackagingListing = metadata?.isPackaging;

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, includeForListingTypes, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields.reduce(pickListingFields, []);
  const customFieldBreed = publicData.customBreed;
  const MIN_LENGTH_FOR_LONG_WORDS = 20;

  const DetailItem = ({ label, text }) => {
    const intl = useIntl();
    const content = richText(text, {
      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
      breakChars: '/',
    });
  
    return (
      <div className={css.sectionDetailsText}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {label}
        </Heading>
        <p className={`${css.text} ${css.whiteText}`}>{content}</p>
      </div>
    );
  };

  return !isPackagingListing && existingListingFields.length > 0 ? (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionDetailsHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {existingListingFields.map(detail => (
           <DetailItem key={detail.key} 
           className={css.detailsRow} 
           label={detail.label} 
           text={detail.value === 'Other' ? customFieldBreed : detail.value} 
           />
        ))}
      </ul>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
